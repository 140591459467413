<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-img`">
      <img src="../../assets/image/press/bg_view.png">
    </div>
    <div :class="`${prefixCls}-navs`">
      <div :class="`${prefixCls}-navs-item ${active === '5'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('5')">
        企业新闻
      </div>
      <div :class="`${prefixCls}-navs-item ${active === '6'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('6')">
        科技资讯
      </div>
      <div :class="`${prefixCls}-navs-item ${active === '7'?prefixCls+'-navs-active' : ''}`" @click="toggleNav('7')">
        海洋动态
      </div>
    </div>
    <div :class="`${prefixCls}-list`" v-loading="loading">
      <div :class="`${prefixCls}-list-item`" v-for="item in listData" :key="item.id" @click="goDetail(item)">
        <img :src="item.picturePath">
        <div :class="`${prefixCls}-list-item-center`" >
          <div :class="`${prefixCls}-list-item-center-title`">
            {{item.title}}
          </div>
          <div :class="`${prefixCls}-list-item-center-detail ${$prefix}-ellipsis-three`">
            {{removeHTMLTag(item.infoContent)}}
          </div>
        </div>
        <div :class="`${prefixCls}-list-item-date`">
          {{item.publishDate}}
        </div>
      </div>
      <div :class="`${prefixCls}-list-pagination`">
        <el-pagination
          background
          :current-page.sync="page.pageIndex"
          :page-size="page.pageSize"
          @size-change="sizeChange"
          @current-change="currentChange"
          layout="total, prev, pager, next"
          :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getPage } from '@/api/list'
import { removeHTMLTag } from '../../utils/util'
export default {
  data () {
    return {
      prefixCls: this.$prefix + '-views-list-press',
      listData: [],
      active: '5',
      loading: false,
      page: {
        total: 0,
        pageSize: 5,
        pageIndex: 1
      }
    }
  },
  props: {
    type: {
      type: String,
      default: '5'
    }
  },
  created () {
    if (this.type) {
      this.active = this.type
    }
    this.getList()
  },
  computed: {

  },
  methods: {
    removeHTMLTag,
    getList () {
      this.loading = true
      const data = { infoType: this.active }
      getPage({ data, params: this.page }).then(res => {
        this.listData = res.data.data.rows
        this.page.total = parseInt(res.data.data.total)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    goDetail (item) {
      // 目标路由对象
      let target = null
      this.$router.getRoutes().findIndex(route => {
        if (route.path === '/press/detail') {
          target = route
          return true
        }
        return false
      })
      // 解析路由对象获取href
      const { href } = this.$router.resolve(target || {})
      window.open(`${href}?id=${item.id}`, '_blank')
    },
    back () {
      this.$router.go(-1)
    },
    toggleNav (id) {
      if (this.active !== id) {
        this.active = id
        this.getList()
      }
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.getList()
    },
    currentChange (val) {
      this.page.pageIndex = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -views-list-press;
  .#{$prefixCls}{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    &-img{
      width: 100%;
      height: 580px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-navs {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: #F5F7FA;
      &-active{
        background: #fff;
      }
      &-item{
        color: #000;
        text-align: center;
        width: 400px;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      width: $content-width;
      padding: 40px 0;
      &-item{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 40px 0;
        border-bottom: 1px solid #EDEDED;
        cursor: pointer;
        &-center {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0 40px;
          flex-grow: 1;
          width: 50%;
          &-title{
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }
          &-detail{
            text-align: left;
          }
        }
        &-date {
          line-height: 40px;
          flex-grow: 1;
          text-align: right;
        }
        img {
          width: 300px;
          height: 170px;
          cursor: pointer;
        }
      }
      &-pagination{
        margin: 20px 0;
      }
    }
  }
</style>
