
import request from '../utils/axios/index'

export const BASE_URL = '/websites-server/rest/frontDesk'
export function getPage ({ data, params }) {
  return request({
    method: 'post',
    url: `${BASE_URL}/getPage`,
    data,
    params
  })
}
